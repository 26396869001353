<template>
  <dashboard-layout>
    <loader-animation v-if="isLoading" />
    <div class="w-full p-5" v-if="!isLoading">
      <div class="p-4 flex">
        <h1 class="text-3xl">
          Participantes na Oportunidade: {{ vacancyTitle }}
        </h1>
      </div>
      <div class="p-4">
        <div class="flex mb-4 justify-between">
          <router-link
            class="bg-green-1000 text-white px-6 py-3 rounded rounded-md focus:outline-none"
            :to="{ name: 'list-vacancies' }"
          >
            Voltar
          </router-link>
        </div>
        <v-client-table
          ref="table"
          :data="participants"
          :columns="columns"
          :options="options"
        >
          <div
            class="flex justify-start items-center"
            slot="salary"
            slot-scope="{ row }"
            v-if="row.status === 'Aprovado(a)'"
          >
            {{ row.salary }}
          </div>
          <div
            class="flex justify-start items-center"
            slot="start_activities"
            slot-scope="{ row }"
            v-if="row.status === 'Aprovado(a)'"
          >
            {{ row.start_activities }}
          </div>
          <div
            class="flex justify-start items-center"
            slot="actions"
            slot-scope="{ row }"
          >
            <router-link
              class="mr-3 text-2xl border-2 border-yellow-500 hover:bg-yellow-500 text-yellow-500 hover:text-white p-1 rounded-lg focus:outline-none focus:shadow-outline"
              :to="{ name: 'curriculum', params: { id: row.id } }"
              v-tooltip="{ content: 'Ver Currículo', placement: 'left' }"
              target="_blank"
            >
              <i class="ri-article-line"></i>
            </router-link>
            <router-link
              class="mr-3 text-2xl border-2 border-green-1002 hover:bg-green-1002 text-green-1002 hover:text-white p-1 rounded-lg focus:outline-none focus:shadow-outline"
              :class="
                !['Aprovado(a)', 'Entrevistado(a)', 'Agendado(a)'].includes(
                  row.status
                )
                  ? 'hidden'
                  : ''
              "
              :to="{
                name: 'interview',
                params: {
                  tipo: 'visualizar',
                  vacancy: $route.params.id,
                  participant: row.id
                }
              }"
              v-tooltip="{
                content: 'Historico',
                placement: 'left'
              }"
            >
              <i class="ri-group-fill"></i>
            </router-link>
          </div>
        </v-client-table>

        <v-client-table
          v-if="participantsExternal != ''"
          ref="table"
          :data="participantsExternal"
          :columns="columnsExternals"
          :options="options"
        >
          <div class="flex justify-center items-center" slot="status">
            Participante Externo
          </div>
        </v-client-table>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import axios from '@/utils/axios';
import DashboardLayout from '../../../layouts/DashboardLayout';
import LoaderAnimation from '../../../components/LoaderAnimation.vue';

export default {
  name: 'SubscribleParticipants',

  title() {
    return `${process.env.VUE_APP_NAME} | Participantes Inscritos`;
  },

  components: {
    DashboardLayout,
    LoaderAnimation
  },

  data() {
    return {
      isLoading: false,
      vacancyTitle: '',
      participants: [],
      participantsExternal: [],
      columns: [
        'id',
        'name',
        'salary',
        'start_activities',
        'status',
        'actions'
      ],
      columnsExternals: ['name', 'status'],
      options: {
        sortable: true,
        perPage: 10,
        perPageValues: [10, 15, 20, 25, 50],
        headings: {
          name: 'Nome',
          salary: 'Sálario',
          start_activities: 'Ini. das Atividades',
          document: 'E-mail',
          resume: 'Currículo',
          actions: 'Ações',
          status: 'Status'
        },
        hiddenColumns: ['id'],
        sortIcon: {
          is: 'glyphicon-sort',
          base: 'glyphicon',
          up: 'glyphicon-chevron-up',
          down: 'glyphicon-chevron-down'
        },
        texts: {
          first: 'Primeiro',
          last: 'Último',
          filter: 'Filtrar:',
          filterBy: 'Filtrar por {column}',
          filterPlaceholder: 'Pesquise aqui...',
          count:
            '{from} - {to} de {count} registros|{count} registros|Um registro',
          limit: 'Registros por página:',
          page: 'Página:',
          noResults: 'Nenhum registro encontrado.',
          columns: 'Colunas'
        },
        pagination: {
          chunk: 5
        }
      }
    };
  },

  methods: {
    fetchParticipantsApproved() {
      axios
        .get(
          `/api/participante/${this.$route.params.id}/participantes-aprovados`
        )
        .then(({ data }) => {
          console.log(data);
          data.participants.forEach(participant => {
            this.participants.push(participant);
          });
        });
    },

    fetchParticipants() {
      this.isLoading = true;
      axios
        .get(`/api/participante/${this.$route.params.id}`)
        .then(({ data }) => {
          data.participantsExternals.forEach(participantsExternal => {
            this.participantsExternal.push(participantsExternal);
          });
          this.isLoading = false;
        });
    },

    fetchVacancy() {
      axios
        .get(`/api/vagas/${this.$route.params.id}/vaga/`)
        .then(({ data }) => {
          this.vacancyTitle = data.data[0].office;
        });
    }
  },

  created: function() {
    this.fetchParticipantsApproved();
    this.fetchParticipants();
    this.fetchVacancy();
  }
};
</script>

<style scoped></style>
