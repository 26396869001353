var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dashboard-layout',[(_vm.isLoading)?_c('loader-animation'):_vm._e(),(!_vm.isLoading)?_c('div',{staticClass:"w-full p-5"},[_c('div',{staticClass:"p-4 flex"},[_c('h1',{staticClass:"text-3xl"},[_vm._v(" Participantes na Oportunidade: "+_vm._s(_vm.vacancyTitle)+" ")])]),_c('div',{staticClass:"p-4"},[_c('div',{staticClass:"flex mb-4 justify-between"},[_c('router-link',{staticClass:"bg-green-1000 text-white px-6 py-3 rounded rounded-md focus:outline-none",attrs:{"to":{ name: 'list-vacancies' }}},[_vm._v(" Voltar ")])],1),_c('v-client-table',{ref:"table",attrs:{"data":_vm.participants,"columns":_vm.columns,"options":_vm.options},scopedSlots:_vm._u([{key:"salary",fn:function({ row }){return (row.status === 'Aprovado(a)')?_c('div',{staticClass:"flex justify-start items-center"},[_vm._v(" "+_vm._s(row.salary)+" ")]):_vm._e()}},{key:"start_activities",fn:function({ row }){return (row.status === 'Aprovado(a)')?_c('div',{staticClass:"flex justify-start items-center"},[_vm._v(" "+_vm._s(row.start_activities)+" ")]):_vm._e()}},{key:"actions",fn:function({ row }){return _c('div',{staticClass:"flex justify-start items-center"},[_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: 'Ver Currículo', placement: 'left' }),expression:"{ content: 'Ver Currículo', placement: 'left' }"}],staticClass:"mr-3 text-2xl border-2 border-yellow-500 hover:bg-yellow-500 text-yellow-500 hover:text-white p-1 rounded-lg focus:outline-none focus:shadow-outline",attrs:{"to":{ name: 'curriculum', params: { id: row.id } },"target":"_blank"}},[_c('i',{staticClass:"ri-article-line"})]),_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: 'Historico',
              placement: 'left'
            }),expression:"{\n              content: 'Historico',\n              placement: 'left'\n            }"}],staticClass:"mr-3 text-2xl border-2 border-green-1002 hover:bg-green-1002 text-green-1002 hover:text-white p-1 rounded-lg focus:outline-none focus:shadow-outline",class:!['Aprovado(a)', 'Entrevistado(a)', 'Agendado(a)'].includes(
                row.status
              )
                ? 'hidden'
                : '',attrs:{"to":{
              name: 'interview',
              params: {
                tipo: 'visualizar',
                vacancy: _vm.$route.params.id,
                participant: row.id
              }
            }}},[_c('i',{staticClass:"ri-group-fill"})])],1)}}],null,true)}),(_vm.participantsExternal != '')?_c('v-client-table',{ref:"table",attrs:{"data":_vm.participantsExternal,"columns":_vm.columnsExternals,"options":_vm.options}},[_c('div',{staticClass:"flex justify-center items-center",attrs:{"slot":"status"},slot:"status"},[_vm._v(" Participante Externo ")])]):_vm._e()],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }